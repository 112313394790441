<template>
  <div class="ymxk-footer-container">
    <div class="img"></div>
    <div class="ymxk-footer-box">
      <div class="ymxk-footer-content-top">
        <img
          class="ymxk-footer-logo"
          src="@/assets/img/ymxk-nav-logo.png"
          alt="logo"
        />
        <ul class="ymxk-footer-link">
          <li><a href="/about">关于我们</a></li>
          <li><a href="/scene">产品与服务</a></li>
          <li><a href="/consumer">企业社会责任</a></li>
          <li><a href="/news">新闻动态</a></li>
        </ul>
        <div class="ymxk-footer-contact-us-box">
          <h6>联系我们</h6>
          <p>地址：北京市朝阳区霄云路26号鹏润大厦B座9层</p>
          <p>客服电话：4008133233</p>
          <p>客服邮箱：finance-service@gome.com.cn</p>
        </div>
        <span class="ymxk-footer-gray-line"></span>
        <div class="ymxk-footer-wechat-qrcode-box">
          <!-- <h6>官方微信二维码</h6> -->
          <ul class="ymxk-footer-qrcode-box">
            <li>
              <img
                src="../assets/img/guanfang.png"
                alt=""
              />
              <span>官方微信公众号</span>
            </li>
            <li>
              <img
                src="../assets/img/fince.png"
                alt=""
              />
              <span>国美金融APP</span>
            </li>
            <li>
              <img
                src="../assets/img/yika.png"
                alt=""
              />
              <span>美易借钱APP</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="ymxk-footer-content-bottom">
        <p>本网站已支持IPV6</p>
        <p>Copyright © 2023 盈美信科技投资有限公司 | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          {{ domainName.includes(isOldDomainName) ? '津ICP备15008982号-1' : '津ICP备15008982号-4'}}
        </a></p>
        <p v-if="!domainName.includes(isOldDomainName)">
          <img
            src="@/assets/img/Beijing_Public_Network_Security.png"
            alt="Beijing_Public_Network_Security"
          />
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052302">
           京公网安备11010502052302号
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps,onMounted } from 'vue';
const { anchor } = defineProps({
  anchor: Boolean
});


// 老域名
let isOldDomainName = process.env.NODE_ENV === 'production' ? 'gomefinance.com.cn' : '1test.gomefinance.com.cn';

// 当前域名
let domainName = location.href;

onMounted(() => {
  if (anchor) {
    window.scrollTo({
      top: document.querySelector('.ymxk-footer-container').getBoundingClientRect().top
    });
  }
});
</script>

<style lang="scss" scoped>
.ymxk-footer-container {
  box-sizing: border-box;
  padding: 40px 0;
  background: linear-gradient(48deg, #4560d2 0%, #172259 100%);
  position: relative;
  .img {
    background-image: url('../assets/img/bg/footer-left.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ymxk-footer-box {
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  .ymxk-footer-content-top {
    display: flex;
    .ymxk-footer-logo {
      width: 213px;
      height: 60px;
      margin: 46px 0 0 20px;
    }
    .ymxk-footer-link {
      width: 116px;
      margin-left: 70px;
      li {
        margin-bottom: 20px;
        a {
          font-size: 16px;
          font-weight: normal;
          color: #ffffff;
          line-height: 16px;
        }
      }
    }
    .ymxk-footer-contact-us-box {
      width: 310px;
      margin-left: 60px;
      h6 {
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
        margin-bottom: 19px;
      }
      p {
        font-size: 14px;
        font-weight: normal;
        color: #BBC3E4;
        line-height: 14px;
        margin-bottom: 17px;
      }
    }
    .ymxk-footer-gray-line {
      width: 2px;
      height: 156px;
      background: #ffffff;
      opacity: 0.2;
      margin: 0 28px 0 20px;
    }
    .ymxk-footer-wechat-qrcode-box {
      h6 {
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
        line-height: 30px;
        margin-bottom: 11px;
        margin-left: 20px;
      }
      .ymxk-footer-qrcode-box {
        display: flex;
        li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
          img {
            width: 100px;
          }
          span {
            font-size: 14px;
            font-weight: normal;
            color: #BBC3E4;
            line-height: 14px;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .ymxk-footer-content-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    p {
      font-size: 14px;
      font-weight: normal;
      color: #BBC3E4;
      line-height: 14px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
        height: 20px;
        opacity: 1;
      }
      a {
        margin-left: 5px;
        text-decoration: none;
        color:#BBC3E4;
      }
      &:nth-child(2){
        margin-bottom: 17px;
      }
      &:last-child{
        margin-bottom: 0px;
        line-height: 25px;
      }
    }
  }
}
</style>
