<template>
  <div class="ymxk-dialog" v-show="isOldDomainName">
    <!-- 遮罩层 -->
    <div class="ymxk-mask">
      <!-- 弹窗 -->
      <div class="ymxk-popup">
        <p class="thankAttention">感谢您的关注~</p>
        <p class="introduction">原国美信科，于2023年2月<strong>品牌焕新</strong>为</p>
        <p class="logo"></p>
        <p class="button" @click="jumpNewDomainName">{{ count }}s后自动跳转至盈美信科</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue';

let timer = null;

onMounted(() => {
  // 老域名www.gomefinance.com.cn下执行以下代码
  if (isOldDomainName) {
    timer = setInterval(() => {
      count.value--;
      if (count.value === 0) {
        clearInterval(timer);
      }
    }, 1000);
  }
});

onUnmounted(() => {
  clearInterval(timer);
});

// 老域名弹窗倒数
let count = ref(3);

// gomefinance.com.cn  ->   https://www.yingmeixk.com
// www.gomefinance.com.cn  ->   https://www.yingmeixk.com
// https://gomefinance.com.cn  ->   https://www.yingmeixk.com
// http://gomefinance.com.cn  ->   https://www.yingmeixk.com

// 老域名
// let domainName = process.env.NODE_ENV === 'production' ? 'gomefinance.com.cn' : 'test.gomefinance.com.cn';
// 因盈美信科无测试环境，判断属于 gomefinance.com.cn 的域名后， 则直接跳转至官网

let isOldDomainName = location.href.includes('gomefinance.com.cn');

const jumpNewDomainName = () => {
  window.location.href = 'https://www.yingmeixk.com';
};

watch(count, (newValue) => {
  if (newValue === 0) {
    jumpNewDomainName();
  }
});

</script>

<style lang="scss" scoped>
.ymxk-dialog {
  .ymxk-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba($color: #000000, $alpha: .6);

    .ymxk-popup {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 460px;
      height: 268px;
      background: url('~@/assets/img/ymxk-dialog-bg.png');
      background-size: 100%;

      .thankAttention {
        margin-top: 40px;
        text-align: center;
        font-size: 18px;
        font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
        line-height: 21px;
      }

      .introduction {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        font-family: FZLTHK--GBK1-0, FZLTHK--GBK1;
        line-height: 17px;
      }

      .logo {
        margin: 24px auto 36px;
        width: 191px;
        height: 40px;
        background: url('~@/assets/img/ymxk-dialog-logo.png');
        background-size: 100%;
        text-align: center;
      }

      .button {
        margin: 0 auto;
        width: 260px;
        height: 48px;
        background: linear-gradient(144deg, #56B6FF 0%, #23A0FF 100%);
        border-radius: 24px;
        font-size: 16px;
        font-family: FZLTZCHK--GBK1-0, FZLTZCHK--GBK1;
        color: #FFFFFF;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>